<template>
  <BOverlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BCard
      aria-hidden="true"
      class="mb-0 pt-50"
    >
      <!-- {{curso}} -->
      <b-form>
        <!-- Curso Info: Input Fields -->
        <BRow>

          <!-- Field: CALENDARIO -->
          <!-- <BCol
            cols="12"
            md="4"

          >
            <BFormGroup
              label="Calendario *"
              label-for="id_calendario"
            >
              <v-select
                id="id_calendario"
                v-model="curso.id_calendario"
                placeholder="Seleccione el calendario..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="option => option.id_calendario"
                :options="optionsCalendarios"
                :class="v$.curso.id_calendario.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
              <div
                v-if="v$.curso.id_calendario.$error"
                id="id_calendarioInfo"
                class="text-danger text-left"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.curso.id_calendario.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol> -->

          <!-- Field: CALENDARIO -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Grado *"
              label-for="id_codigo_grado"
            >
              <v-select
                id="id_codigo_grado"
                v-model="curso.id_codigo_grado"
                placeholder="Seleccione el grado..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="option => option.id_codigo_grado"
                :options="optionsCodigoGrados"
                :class="v$.curso.id_codigo_grado.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
                <!-- :disabled="optionsPaises.length === 0" -->
              <div
                v-if="v$.curso.id_codigo_grado.$error"
                id="id_codigo_gradoInfo"
                class="text-danger text-left"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.curso.id_codigo_grado.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol>

          <!-- Field: LETRA -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Letra *"
              label-for="letra"
            >
              <BFormInput
                id="letra"
                v-model="curso.letra"
                placeholder="Ingrese el letra"
                :state="v$.curso.letra.$error === true
                ? false
                : null"
                @blur.native="v$.curso.letra.$touch"
              />
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.curso.letra.$error"
                id="letraInfo"
                class="text-right"
              >
                <p v-for="error of v$.curso.letra.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- Field: TOTAL ALUMNOS -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Total alumnos/as"
              label-for="total_alumnos"
            >
              <BFormInput
                id="total_alumnos"
                v-model="curso.total_alumnos"
                placeholder="Ingrese la letra"
              />
                <!-- :state="v$.curso.total_alumnos.$error === true
                ? false
                : null"
                @blur.native="v$.curso.total_alumnos.$touch" -->
              <!-- Mensajes Error Validación -->
              <!-- <BFormInvalidFeedback
                v-if="v$.curso.total_alumnos.$error"
                id="total_alumnosInfo"
                class="text-right"
              >
                <p v-for="error of v$.curso.total_alumnos.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback> -->
            </BFormGroup>
          </BCol>

          <!-- Field: TIPO JORNADA -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Tipo de jornada *"
              label-for="tipo_jornada"
            >
              <v-select
                id="tipo_jornada"
                v-model="curso.tipo_jornada"
                placeholder="Seleccione el tipo de jornada..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="option => option.tipo_jornada"
                :options="optionsTipoJornadas"
                :class="v$.curso.tipo_jornada.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
              <div
                v-if="v$.curso.tipo_jornada.$error"
                id="tipo_jornadaInfo"
                class="text-danger text-left"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.curso.tipo_jornada.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol>

          <!-- Field: ESPECIALIODAD -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Especialidad"
              label-for="id_especialidad"
            >
              <v-select
                id="id_especialidad"
                v-model="curso.id_especialidad"
                placeholder="Seleccione la especialidad..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="option => option.id_especialidad"
                :options="optionsEspecialidad"
              />
            </BFormGroup>
          </BCol>

          <!-- Field: COMBINADO -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Combinado"
              label-for="combinado"
              class="text-center"
            >
              <!-- label-cols-md="6" -->
              <BFormCheckbox
                checked="true"
                value="1"
                v-model="curso.combinado"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
              </BFormCheckbox>

            </BFormGroup>
          </BCol>

          <!-- Field: ALTERNATIVA -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Alternativa"
              label-for="alternativa"
              class="text-center"
            >
              <BFormCheckbox
                checked="true"
                value="1"
                v-model="curso.alternativa"
                class="custom-control-success"
                name="check-button"
                switch
              >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </BFormCheckbox>
            </BFormGroup>
          </BCol>

          <!-- Field: infraestructura -->
          <BCol
            cols="12"
            md="4"
          >
            <BFormGroup
              label="Infraestructura"
              label-for="infraestructura"
              class="text-center"
            >
              <!-- label-cols-md="6" -->
              <BFormCheckbox
                checked="true"
                value="1"
                v-model="curso.infraestructura"
                class="custom-control-success"
                name="check-button"
                switch
              >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </BFormCheckbox>

            </BFormGroup>
          </BCol>

        </BRow>


        <colLinea />

        <!-- Action Buttons -->
        <btnSubmit
          v-if="cargando"
          variant="primary"
          :btnText="btnSubmit"
          :modulo="nombre_permiso"
          @processBtn="submitOption"
        />
      </b-form>
    </BCard>
  </BOverlay>
</template>

<script>

// Etiquetas //
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BCard, BInputGroup, BInputGroupPrepend,
  BFormCheckbox,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

// Imágen //
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import { mapGetters, mapActions } from 'vuex'

// Validaciones //
import useVuelidate from '@vuelidate/core'
import { required
  , maxLength, email, helpers } from '@vuelidate/validators'

// Componentes //
import btnSubmit from '../../components/Form/btnSubmit.vue'
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    vSelect,
    BOverlay,
    BCard,
    BFormCheckbox,

    btnSubmit,
    colLinea,
  },
  data() {
    return {
      cargando: true,
      nombre_permiso: 'cursos',
      optionsTipoJornadas: [
        {
          tipo_jornada: 1,
          title: 'Mañana',
        },
        {
          tipo_jornada: 2,
          title: 'Tarde',
        },
        {
          tipo_jornada: 3,
          title: 'Mañana y tarde',
        },
        {
          tipo_jornada: 4,
          title: 'Vespertina / Nocturna',
        },
      ],
      optionsCodigoGrados: [
        {
          id_codigo_grado: 1,
          title: '1º Básico',
        },
        {
          id_codigo_grado: 2,
          title: '2º Básico',
        },
        {
          id_codigo_grado: 3,
          title: '3º Básico',
        },
        {
          id_codigo_grado: 4,
          title: '4º Básico',
        },
      ],
      optionsCalendarios: [],

      optionsEspecialidad: [
        {
          id_especialidad: 1,
          title: 'especialidad',
        },
      ],
    }
  },
  props: {
    curso: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Curso',
    },
  },
  validations() {
    return {
      curso: {
        id_calendario: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_codigo_grado: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        letra: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 1 caracter.'
            , maxLength(1)),
        },
        combinado: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        tipo_jornada: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      getCalendarios: 'calendarios/getCalendarios',
      getUser: 'auth/user',
    }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    disabledExport() {
      return this.chkCount()
    },
  },
  mounted() {
    this.cargarCalendario()
  },
  methods: {
    ...mapActions({
      fetchCalendariosEstablecimiento: 'calendarios/fetchCalendariosEstablecimiento',
      updateCursoPeriodo: 'cursos/updateCursoPeriodo',
      removeCursos: 'cursos/removeCursos',
    }),
    cargarCalendario() {
      this.fetchCalendariosEstablecimiento(this.getUser.id_establecimiento).then(() => {
        this.curso.id_calendario = this.getCalendarios.id
      })
    },
    submitOption() {
      this.v$.curso.$touch()
      if (!this.v$.curso.$invalid) {
        this.$emit('processForm', this.curso)
      }
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
